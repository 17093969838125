
@media screen and (max-width: 600px) { 
  .Cubes {
    flex-direction: column;
  }

  .console-container {
    font-size: 1.1em;
  }

  .header.main-title {
    font-size: 40px;
  }


  .Contact h1 {
    font-size: 28px;
  }

  .Contact .ContactIcons .mediaIcon {
    width: 100px;
    height: 100px;
  }

}



body{
  background-color: black;
  background: black;
} 



.header {
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: lighter;
}

.header.main-title {
  font-size: 50px;
}

h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: lighter;
  padding-left: 30px;
  padding-right: 30px; 
  margin : 0;
  padding-bottom: 0;
}

.Main .header{
  padding-top:20px;
  padding-bottom: 5px;
}

.header {
  padding-bottom: 35px;
}

style {
  word-wrap: break-word;
}

.Cubes {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-top: 100px;
  justify-content: space-around;
  
}

.SkillsandProjects {
  color: white;
}


html, body { 
  height: 100%;
  margin: 0;
  padding:0;
  background-color: black;
}

.about {
  color: black;
  width: 100%; 
  height: 100%;
  min-height: 700px;
}

.Contact {
  color: black;
  height: 900px;
  width: 100%;
}


/*Contact Page */
#githubIcon {
  animation: rotation 2s infinite linear;
}
/* Took out -webkit-animation prefix before @keyframes to get it to work  */
@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}


.mediaIcon {
  position: absolute;
  top: 100px;
  width: 200px;
  height: 200px;
}

.githubIcon {
  left: 20%;
} 

.linkedinIcon {
  right: 20%;
}

#linkedinIcon {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}

#githubIcon,
#linkedinIcon { 
  width: 100%;
  height: 100%; 
}

