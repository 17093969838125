.main {
    height: 1000px;
  
    header {
     
      color: red;
      
    }
  
  .site-content {
    height: 100%;
    display: flex;
    flex-direction: row;
  
    #holder {
      
      flex: 1;
      height: 100%;
      
    }
  
    .content {
      
      height: 100%;
      flex: 2;
  
      .box {
        margin-bottom: 100px;
      }
    }
  }
    
  
  
  }