.hidden {
  opacity:0;
}
.console-container {
 
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size:1.5em;
  display:block;
  color:white;
  margin:auto;
}
.console-underscore {
  display:inline-block;
  position:relative;
  top:-0.14em;
  left:10px;
}

.about .console-container {
  font-size: 1.1em;
  color: black;
}

.about .console-underscore {
  color: black;
}