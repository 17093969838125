.box {
    margin: auto;
    width: 200px;
    height: 100px;
    position: relative; /* only for the fadebox! */
  
    img {
      width: 100%;
      height: 100%;
    }
  
    .caption {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: white;
    }
  
    .fadebox {
        opacity: 0;
        font-size: 40px;
        position: absolute;
        top: 0;
        left: 0;
        color:wheat;
        background-color:rgba(10, 10, 10, 0.9);
        width: 100%;
        height: 100%;
        transition: all 400ms ease-out;
        text-align:center; 
  
        &:hover {
          opacity: 1;
  
          .fadebox-text {
            opacity: 1;
            transform: translateY(-58px); // height/2 - font-size/2: 
          }
        }
        
       .fadebox-text {
          font-size: 16px;
          height:0;
          opacity:1;
          transition-delay: 0s;
          transition-duration: 0.4s;
          position: relative;
          top: 100%;
        }
  
        
    }
  
  }